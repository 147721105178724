











import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "index",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
