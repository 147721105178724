



































import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
export default defineComponent({
  name: "MyMenu",
  props: {
    // 菜单
    menuData: {
      type: Array,
      default: [],
    },
    // 判断子类、父类 默认父类
    Parent: {
      type: Boolean,
      default: true,
    },
    // 标识高亮
    activeId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const expandedKeys = ref([]); // 当前列表需要展开的节点id组成的数组
    const openFn = (data: any, val: number, bool?: boolean): any => {
      if (props.menuData !== []) {
        for (const element of data) {
          if (element.id == val) {
            // return bool ? [element.id] : ""; // 默认第一个，打开
            return [element.id];
          }
          if (element.children) {
            let far = openFn(element.children, val);
            if (far) {
              if (bool) far.concat(element.pid);
              let _arr = far.concat(element.id);
              return _arr.length > 1 ? _arr.reverse() : _arr;
            }
          }
        }
      }
    };
    // 变化更新目录active
    watch(
      () => props.activeId,
      (newval) => {
        console.log(newval);
        if (newval) {
          updateMenu();
        }
      }
    );
    // 更新目录
    const updateMenu = () => {
      if (props.menuData.length > 0) {
        expandedKeys.value = openFn(props.menuData, props.activeId);
      }
    };
    const itemNodeClick = (item: { id: never; children: Array<any> }) => {
      emit("node-click", item);
      expandedKeys.value = openFn(props.menuData, item.id, true).reverse();
      // if (item.children && item.childr gen.length) {
      //   let index = expandedKeys.value.indexOf(item.id);
      //   if (index > -1) {
      //     // 如果当前节点id存在数组中，则删除
      //     expandedKeys.value.splice(index, 1);
      //   } else {
      //     // 如果当前节点id不存在数组中，则添加
      //     expandedKeys.value.push(item.i gbd);
      //   }
      // }
    };
    const isOpen = (id: never) => {
      // 判断节点id在不在数组中，在则显示，不在则隐藏
      if (expandedKeys.value) {
        return expandedKeys.value.includes(id);
      }
    };

    // const hasArr = (id: number) => {
    //   if (id) {
    //     expandedKeys.value.includes(id);
    //   }
    // };

    return {
      open,
      // showchildren,
      itemNodeClick,
      isOpen,
      // activeid,
      expandedKeys,
      updateMenu,
    };
  },
});
