












import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Menu from "@/components/menu/menuview.vue";
export default defineComponent({
  name: "warp",
  components: {
    Menu,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    // 菜单
    menuData: {
      type: Array,
      default: [],
    },
    activeIndex: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const activeId = ref(props.activeIndex);
    const menu: any = ref(null);

    onMounted(() => {
      menu.value.updateMenu();
    });

    watch(
      () => props.activeIndex,
      (newval) => {
        activeId.value = newval;
      },
      {
        immediate: true,
      }
    );
    const nodeClick = (val: any) => {
      emit("node-click", val);
      console.log(val);
      activeId.value = val.id;
    };
    return {
      activeId,
      nodeClick,
      menu,
    };
  },
});
