
















import { defineComponent, ref, watch } from "@vue/composition-api";
export default defineComponent({
  name: "html",
  props: {
    contentTitle: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    // 是否表格
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const items: IProps = ref([]);
    watch(
      () => props.content,
      (newval) => {
        if (props.isTable) {
          items.value = JSON.parse(newval);
        }
      },
      {
        immediate: true,
      }
    );
    return {
      fields: [
        {
          key: "key",
          label: "名称",
        },
        {
          key: "value",
          label: "详细",
        },
      ],
      items,
    };
  },
});
