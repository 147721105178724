



































/**
 * @name 关于公司
 * */
import { defineComponent, ref, onMounted, toRefs } from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import Menu from "@/components/menu/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import HtmlInfo from "@/components/content/html.vue";
import { useList, getListToTypeFn, useDom } from "@/use";
export default defineComponent({
  components: {
    bgImg,
    Menu,
    offcanvas,
    InfoWarp,
    HtmlInfo,
  },
  setup() {
    const src = ref("about/about.jpeg");
    const title = "公司简介";
    const { Mdata, contentData, nodeClick, meunActive } =
      getListToTypeFn("aboutUs");
    const { offcanvas, showOffcanvasMenu } = useDom();
    const arr = ref([
      { label: "公司简介", id: 1 },
      { label: "企业文化", id: 2 },
      { label: "资质证书", id: 3 },
    ]);
    const perPage = ref(3);
    const currentPage = ref(1);
    const items = [
      {
        name: "公司简介",
        id: 1,
      },
    ];

    // const {} = useList(() => {
    //   () =>
    //     new Promise((reslove) => {
    //       // 获取表格数据、导入接口
    //       reslove({
    //         items: [],
    //       });
    //     });
    // });

    return {
      src,
      title,
      arr,
      items,
      // activeId,
      currentPage,
      perPage,
      nodeClick,
      Mdata,
      ...toRefs(contentData),
      ...toRefs(meunActive),
      offcanvas,
      showOffcanvasMenu,
      meunActive,
    };
  },
});
