





































import { defineComponent, ref } from "@vue/composition-api";
export default defineComponent({
  name: "index",
  setup() {
    const showMenu = ref(false);
    //  控制隐藏与展示
    const showOffcanvasMenu = () => {
      showMenu.value ? (showMenu.value = false) : (showMenu.value = true);
    };
    return {
      showMenu,
      showOffcanvasMenu,
    };
  },
});
